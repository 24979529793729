/* eslint-disable camelcase */
import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'
import upperCase from 'lodash/upperCase'
import sha256 from 'crypto-js/sha256'
import enc from 'crypto-js/enc-hex'
import { NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY } from './client-side-config'
import Batcher from 'batcher-js'
import { TDesignJourney } from '@pageFeatures/design/types'
import { legacyDesignJourneyToNew } from '@pageFeatures/design/utils'
import { METADATA_TAG } from '@utils/constants'
import { arrayKeyValueToObject } from '@framework/api/customer/account/profile/utils'
import { IBigCommerceOrder } from 'app/_services/Commerce/types/bigCommerceOrder'

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID
export const BRAND = 'noissue'

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
    ga: any
  }
}

interface IFormSubmitTrackEvent {
  event: 'form-submit'
  formName: string
  eventStatus: 'complete' | 'fail'
  failureReason?: string
}

interface IDesignChoiceTrackEvent {
  event: 'designer-design-choice'
  action: string
  selectedProductSlug: string
}

interface IGuidelineDownloadTrackEvent {
  event: 'designer-download-template'
  designTemplateFormat: string
}
export type TDesignerControlsDisplayTypes =
  | 'RadioTile'
  | 'Dropdown'
  | 'QuantitySelector'
  | 'ColorSelector'
  | 'ButtonTilesSelector'
  | 'TextualAnswer'
  | 'CardsSelectorSlider'
  | 'FontSelector'
  | 'AccordionStep'
  | 'LogoWidgetControls'
  | 'PatternWidgetControls'
  | 'IconButton'

interface IDesignerInteractionEvent {
  event: 'designer-interaction'
  designerName: string
  componentName: string
  componentInformation: string | string[]
  selectedProductSlug: string
  displayType: TDesignerControlsDisplayTypes
}

interface ITrackingData {
  currencyCode: string
  name: string
  sku: string
  price: number
  quantity: string
  categorySlug?: string
  optionValues?: {
    id: number
    label: string
    option_display_name: string
    option_id: number
  }[]
}

export const dataLayerPushWithTimestamp = (event) => {
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    ...event,
    timestamp: Date.now(),
  })
}

export const trackFormSubmit = (event: IFormSubmitTrackEvent) => {
  dataLayerPushWithTimestamp(event)
}

export const trackGuidelineDownload = (event: IGuidelineDownloadTrackEvent) => {
  dataLayerPushWithTimestamp(event)
}

export const trackDesignerInteraction = (event: IDesignerInteractionEvent) => {
  dataLayerPushWithTimestamp(event)
}

export const trackDesignerFileUpload = ({
  fileUrl,
  productSlug,
  designJourney,
}: {
  fileUrl: string
  productSlug: string
  designJourney: TDesignJourney
}) => {
  const event = {
    event: 'designer-file-upload',
    fileUrl,
    selectedProductSlug: productSlug,
    selectedDesignJourney: designJourney,
  }
  dataLayerPushWithTimestamp(event)
}

export const trackPlusEditorFileUpload = ({
  fileUrl,
  productSlug,
}: {
  fileUrl: string
  productSlug: string
}) => {
  const event = {
    event: 'plus-editor-file-upload',
    fileUrl,
    selectedProductSlug: productSlug,
  }
  dataLayerPushWithTimestamp(event)
}

export const trackDesignChoice = ({
  selectedOptionLabel,
  selectedProductSlug,
}: {
  selectedOptionLabel: string
  selectedProductSlug: string
}) => {
  const event: IDesignChoiceTrackEvent = {
    event: 'designer-design-choice',
    action: selectedOptionLabel,
    selectedProductSlug,
  }

  dataLayerPushWithTimestamp(event)
}

export const pageview = (url) => {
  dataLayerPushWithTimestamp({
    event: 'pageview',
    page: url,
  })
}

function slugToCategory(slug) {
  if (!slug) {
    return
  }

  return slug
    .split('/')
    .filter(Boolean)
    .filter((element) => !element.includes('?') && !element.includes('#')) // And filter query param
    .pop()
    .split('-')
    .map((component) => capitalize(component))
    .join(' ')
    .trim()
}

function pickLargestDiscountPromoCode(coupons) {
  const { code } = coupons.reduce(
    (acc, coupon) => {
      // Skip system coupon code for editing order
      if (/EditOrder/.test(coupon.code)) {
        return acc
      }

      if (
        parseFloat(acc.discount) < parseFloat(coupon.discount || coupon.amount)
      ) {
        return {
          code: coupon.code,
          discount: (coupon.discount || coupon.amount || 0.0).toString(),
        }
      }

      return acc
    },
    { code: '', discount: '0.0' }
  )

  return code || ''
}

const getDesignJourneyFromCustomization = (options) => {
  const customizationOption = options.find(
    (option) => option.name === 'Customizations'
  )
  let customizationValue = customizationOption?.value

  if (typeof customizationValue === 'string') {
    customizationValue = JSON.parse(customizationOption?.value)
  }

  if (!isEmpty(customizationValue)) {
    let designJourney = customizationValue.designJourney
    if (!designJourney) {
      designJourney =
        customizationValue?.version === 'V1'
          ? legacyDesignJourneyToNew[customizationValue?.version]
          : customizationValue?.version
    }

    return designJourney
  }
  return null
}

function asNumber(value) {
  return isNaN(value) ? undefined : Number(value)
}

function getItemPrice(totalExTax, discount) {
  if (typeof asNumber(discount) !== 'number') {
    return asNumber(totalExTax)
  }
  if (typeof asNumber(totalExTax) === 'number') {
    return asNumber(totalExTax) - asNumber(discount)
  }
  return asNumber(totalExTax)
}

export const trackAddToCart = ({ currency, lineItems }) => {
  // Measure when a product is added to a shopping cart
  dataLayerPushWithTimestamp({ ecommerce: null }) // Clear the previous ecommerce object.
  dataLayerPushWithTimestamp({
    event: 'addToCart',
    ecommerce: {
      currencyCode: currency.code,
      add: {
        // 'add' actionFieldObject measures.
        products: lineItems.map((item, index) => {
          const { quantity, variant, options, categorySlug } = item
          const { sku, price } = variant || {}
          const designJourney = getDesignJourneyFromCustomization(options)
          const category = slugToCategory(categorySlug)

          return {
            name: category, // Name or ID is required.
            id: categorySlug,
            brand: BRAND,
            category,
            variant: sku,
            price,
            quantity,
            index,
            designJourney: designJourney,
          }
        }),
      },
    },
  })
}

export const trackRemoveFromCart = ({ currency, lineItems }) => {
  dataLayerPushWithTimestamp({ ecommerce: null }) // Clear the previous ecommerce object.
  dataLayerPushWithTimestamp({
    event: 'removeFromCart',
    ecommerce: {
      currencyCode: currency.code,
      remove: {
        products: lineItems.map((item) => {
          const { quantity, variant, options, categorySlug } = item
          const { sku, price } = variant || {}
          const designJourney = getDesignJourneyFromCustomization(options)
          const category = slugToCategory(categorySlug)

          return {
            name: category, // Name or ID is required.
            id: categorySlug,
            price,
            quantity,
            category,
            brand: BRAND,
            variant: sku,
            designJourney,
          }
        }),
      },
    },
  })
}

export const trackProductImpressionAndAction = (
  modifiedField: string,
  trackingData: ITrackingData
) => {
  dataLayerPushWithTimestamp({ ecommerce: null }) // Clear the previous ecommerce object.
  dataLayerPushWithTimestamp({
    event: 'changeProductSelection',
    ecommerce: {
      currencyCode: trackingData.currencyCode,
      detail: {
        actionField: { list: modifiedField }, // 'detail' actions have an optional list property.
        products: [
          {
            name: trackingData.name, // Name or ID is required.
            id: trackingData.sku,
            price: trackingData.price,
            variant: trackingData.quantity,
          },
        ],
      },
    },
  })
}

export const trackDesignNowAction = (trackingData: ITrackingData) => {
  dataLayerPushWithTimestamp({ ecommerce: null }) // Clear the previous ecommerce object.
  dataLayerPushWithTimestamp({
    event: 'designNowButtonClick',
    ecommerce: {
      currencyCode: trackingData.currencyCode,
      detail: {
        actionField: { step: 1, option: 'Continue' }, // 'detail' actions have an optional list property.
        products: [
          {
            name: trackingData.name,
            id: trackingData.sku,
            price: trackingData.price,
            variant: trackingData.quantity,
          },
        ],
      },
    },
  })
}

export const trackRequestQuoteAction = (productName: string) => {
  window?.dataLayer?.push({ ecommerce: null }) // Clear the previous ecommerce object.
  window?.dataLayer?.push({
    event: 'designNowButtonClick',
    ecommerce: {
      detail: {
        actionField: {
          step: 1,
          option: 'Request Quote',
        },
        // 'detail' actions have an optional list property.
        products: [
          {
            name: productName,
          },
        ],
      },
    },
  })
}

export const trackOrderConfirmation = (order: IBigCommerceOrder, products) => {
  if (isEmpty(order)) {
    return
  }

  const coupons = []

  const eventProducts = products.map((product) => {
    if (!isEmpty(product.applied_discounts)) {
      coupons.push(...product.applied_discounts)
    }

    // get metadata variant
    const metadataOption =
      product?.product_options?.find(
        (option) => option.display_name?.toLowerCase() === METADATA_TAG
      ) ?? {}

    const metadata = JSON.parse(metadataOption?.value ?? '{}')
    const category = slugToCategory(metadata?.pdpSlug)

    return {
      name: category, // Name or ID is required.
      id: metadata?.pdpSlug,
      price: getItemPrice(
        product.total_ex_tax,
        product.applied_discounts?.[0]?.amount
      ),
      brand: BRAND,
      variant: product.sku,
      category, // : `${variantTypeFrom(metadata?.pdpSlug)}/${category}`,
      quantity: product.quantity,
    }
  })

  const customerEmail = order.billing_address?.email || ''
  const customerPhone = order.billing_address?.phone || ''

  dataLayerPushWithTimestamp({ ecommerce: null }) // Clear the previous ecommerce object.
  dataLayerPushWithTimestamp({
    event: 'purchase',
    ecommerce: {
      currencyCode: order.currency_code,
      purchase: {
        actionField: {
          id: order.id, // Transaction ID. Required for purchases and refunds.
          affiliation: `${order.channel_id}`,
          revenue: order.total_inc_tax, // Total transaction value (incl. tax and shipping)
          tax: order.total_tax,
          shipping: order.shipping_cost_ex_tax,
          coupon: pickLargestDiscountPromoCode(coupons) || undefined,
        },
        customer: {
          phone: enc.stringify(sha256(customerPhone)),
          email: enc.stringify(sha256(customerEmail)),
          customer_email: customerEmail,
        },
        products: eventProducts,
      },
    },
  })
}

export const track404 = ({ url }) => {
  // Measure when a product is added to a shopping cart
  dataLayerPushWithTimestamp({
    event: 'page404NotFound',
    attributes: {
      url,
    },
  })
}

export const trackCheckout = ({ currency, lineItems }) => {
  dataLayerPushWithTimestamp({ ecommerce: null }) // Clear the previous ecommerce object.
  dataLayerPushWithTimestamp({
    event: 'checkout',
    ecommerce: {
      currencyCode: currency?.code,
      checkout: {
        actionField: { step: 1, option: 'Login' },
        products: lineItems.map((item) => {
          const { quantity, variant, options, categorySlug } = item
          const { sku, price } = variant
          const designJourney = getDesignJourneyFromCustomization(options)
          const category = slugToCategory(categorySlug)

          return {
            name: category, // Name or ID is required.
            id: categorySlug,
            price,
            quantity,
            brand: BRAND,
            category,
            variant: sku,
            designJourney,
          }
        }),
      },
    },
  })
}

/**
 * @param invocations {[{list: string, item: {name: string, slug: string, price?: string, productInfo: object}, position: number}]}
 */
const doTrackProductImpression = (invocations) => {
  dataLayerPushWithTimestamp({ ecommerce: null }) // Clear the previous ecommerce object.
  dataLayerPushWithTimestamp({
    event: 'product-impression',
    ecommerce: {
      currencyCode: NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY,
      impressions: uniqBy(
        invocations.map((invocation) => {
          const { list, item, position } = invocation
          const { name, productSlug, slug, price, productInfo } = item
          return {
            name: name?.trim(),
            id: productSlug ?? slug,
            brand: BRAND,
            category: slugToCategory(productSlug ?? slug),
            ...(price ? { price: price.match(/[+-]?\d+(\.\d+)?/g)[0] } : {}),
            list, // 'you may also like',
            position: position,
            variant: productInfo?.variantSku ?? productInfo?.sku,
          }
        }),
        function (impression: any) {
          return `${impression.category}-${impression.name}`
        }
      ),
    },
  })
}
const batcher = Batcher(doTrackProductImpression, {
  interval: 250, // milliseconds
})

/**
 *
 * @param {{list: string, item: {name: string, slug: string, price?: string, productInfo?: object}, position: number}}
 */
export const trackProductImpression = ({ list, item, position }) => {
  batcher({ list, item, position }, doTrackProductImpression)
}

/**
 * @param {{list: string, item: {name: string, slug: string, price?: string, productInfo?: object}, position: number}}
 */
export const trackProductClick = ({ list, buttonText, item, position }) => {
  const { name, slug, price, productInfo, productSlug } = item
  dataLayerPushWithTimestamp({ ecommerce: null }) // Clear the previous ecommerce object.
  dataLayerPushWithTimestamp({
    event: 'product-click',
    ecommerce: {
      currencyCode: NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY,
      click: {
        actionField: {
          list,
          buttonText,
        },
        products: [
          {
            name: name?.trim(),
            brand: BRAND,
            category: slugToCategory(productSlug ?? slug),
            id: productSlug ?? slug,
            ...(price ? { price: price.match(/[+-]?\d+(\.\d+)?/g)[0] } : {}),
            // FIXME: This product click is very general product, it doesn't contain any variant
            //  so in general, those following value doesn't make much sense
            position: position,
            variant: productInfo?.variantSku ?? productInfo?.sku,
          },
        ],
      },
    },
  })
}

export const trackProductDetailView = (trackingData: ITrackingData) => {
  if (isEmpty(trackingData)) {
    return
  }
  dataLayerPushWithTimestamp({ ecommerce: null }) // Clear the previous ecommerce object.
  dataLayerPushWithTimestamp({
    event: 'product-detail-view',
    ecommerce: {
      currencyCode: upperCase(
        trackingData.currencyCode || NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY
      ),
      detail: {
        actionField: {}, // 'detail' actions have an optional list property.
        products: [
          {
            name: trackingData.name?.trim(), // Name or ID is required.
            id: trackingData.categorySlug,
            brand: BRAND,
            category: slugToCategory(trackingData.categorySlug), // parent category,
            variant: trackingData.sku,
            price: trackingData.price,
          },
        ],
      },
    },
  })
}

export const trackCheckoutStep = ({
  currency,
  lineItems,
  step = 1,
  option = undefined,
}) => {
  const ecommerce = {
    currencyCode: currency?.code,
    checkout: {
      actionField: { step, ...(option ? { option } : {}) },
      products: lineItems.map((item) => {
        const { quantity, variant, categorySlug } = item
        const { sku, price } = variant
        const category = slugToCategory(categorySlug)

        return {
          name: category, // Name or ID is required.
          id: categorySlug,
          price,
          quantity,
          brand: BRAND,
          category,
          variant: sku,
        }
      }),
    },
  }
  dataLayerPushWithTimestamp({ ecommerce: null }) // Clear the previous ecommerce object.
  dataLayerPushWithTimestamp({
    event: 'checkout-step',
    ecommerce,
  })
}

type TNavigationTrackingType =
  | 'Logo'
  | 'Primary Menu'
  | 'Secondary Menu'
  | 'Selection Title'
  | 'Selection Item'
  | 'Dropdown CTA'
  | 'Dropdown Announcement Bar'
  | 'Sitewide Announcement Bar'
  | 'Dropdown Tile'
  | 'Dropdown Tile Section Link'
  | 'Social Media Icon'

export const trackNavigationClick = ({
  clickLocation,
  clickItem,
}: {
  clickLocation: TNavigationTrackingType
  clickItem: string
}) => {
  dataLayerPushWithTimestamp({
    event: 'navigation-click',
    clickLocation: clickLocation,
    clickItem: clickItem,
  })
}

export const trackABExperiment = (experimentId, variantId) => {
  dataLayerPushWithTimestamp({
    event: 'experiment_impression',
    experiment_id: experimentId,
    variant_id: variantId,
    send_to: 'GA_MEASUREMENT_ID',
  })
}

export const trackCaroselClick = (event: {
  direction: 'left' | 'right'
  componentIdentifier: string
  pageUrl: string
}) => {
  dataLayerPushWithTimestamp({
    event: 'carousel',
    action: 'carousel-click',
    label: event.direction,
    componentIdentifier: event.componentIdentifier,
    direction: event.direction,
    pageUrl: event.pageUrl,
  })
}

export const trackCTAClick = (event: {
  ctaLinkUrl: string
  componentIdentifier: string
  pageUrl: string
  ctaLabel: string
}) => {
  if (event.ctaLinkUrl || event.componentIdentifier || event.ctaLabel) {
    dataLayerPushWithTimestamp({
      event: 'cta-click',
      ctaLink: event.ctaLinkUrl,
      componentIdentifier: event.componentIdentifier,
      pageUrl: event.pageUrl,
      ctaLabel: event.ctaLabel,
    })
  }
}

export const trackConfigurableCardImpression = (event: {
  linkUrl: string
  componentIdentifier: string
  cardTitle: string
  pageUrl: string
}) => {
  if (event.linkUrl || event.componentIdentifier || event.cardTitle) {
    dataLayerPushWithTimestamp({
      event: 'product-impression',
      linkUrl: event.linkUrl,
      cardTitle: event.cardTitle,
      componentIdentifier: event.componentIdentifier,
      pageUrl: event.pageUrl,
    })
  }
}

export const trackConfigurableListImpression = (event: {
  componentIdentifier: string
  title: string
  pageUrl: string
}) => {
  if (event.componentIdentifier || event.title) {
    dataLayerPushWithTimestamp({
      event: 'product-impression',
      title: event.title,
      componentIdentifier: event.componentIdentifier,
      pageUrl: event.pageUrl,
    })
  }
}

export const trackProductImpressionForCategoryCollectionComponent = (event: {
  componentIdentifier: string
  productName: string
  slug: string
  pageUrl: string
}) => {
  if (event.componentIdentifier || event.productName || event.slug) {
    dataLayerPushWithTimestamp({
      event: 'product-impression',
      currencyCode: NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY,
      productName: event.productName,
      slug: event.slug,
      componentIdentifier: event.componentIdentifier,
      pageUrl: event.pageUrl,
    })
  }
}

export const trackProfileFormFilled = (payload: {
  fields: { name: string; value: any }[]
}) => {
  dataLayerPushWithTimestamp({
    event: 'profile-completed',
    ...arrayKeyValueToObject(payload.fields),
  })
}
